<script>
</script>

<section>
  <p class="body-text">
    Imagine you're a doctor trying to detect a disease with a medical test. When you test someone, there are two types of mistakes you can make. A Type I error happens when the test says someone has the disease when they actually don't—like a false alarm. A Type II error is when the test says someone doesn't have the disease when they actually do—like missing the problem. The power of the test is its ability to correctly identify those with the disease. In other words, a powerful test is good at detecting the disease when it's really there, reducing the chance of a Type II error. Understanding these concepts helps in evaluating how reliable and accurate tests and experiments are.
  </p>
  <br/>
  <p class="body-text">This article serves as an interactive introduction to the concept of Type I, Type II Errors and Power.</p>
</section>

<style>
</style>
