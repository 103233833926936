<svelte:head>
  <title>Explained: Type I, Type II Errors and Power</title>
  <meta charset="utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width,initial-scale=1" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
  />
  <!-- add desc -->
  <meta name="description" content="An interactive explanation of Type I, Type II Errors and Power in Statistics" />
  <!-- add image -->
  <meta property="og:image" content="" />
  <!-- add title -->
  <meta property="og:title" content="Explained: Type I, Type II Errors and Power" />
  <!-- add prop -->
  <meta
    property="og:description"
    content="An interactive explanation of Type I, Type II Errors and Power in Statistics"
  />
  <meta property="og:image:width" content="1000" />
  <meta property="og:image:height" content="630" />
</svelte:head>
