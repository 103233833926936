<script>
    import { sampleSize } from "../stores";
</script>

<h1 class="body-header">Form a Sample</h1>

<p class="body-text">
    Let's try to collect a real sample based on the data introduced earlier! Try to pick a sample size that is at least 30 so we can establish an assumption that the sample is roughly Normally Distributed.
</p>

<br />
<p class="body-text">
    We would also like to avoid picking a "too large" sample since the purpose of collecting sample is to give a "good enough" analysis on the population without collecting all datapoints from the population.

    For purpose of demonstration, let's pick a sample size at <strong>100</strong>.
</p>

<br />

<style>
</style>
